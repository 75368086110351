import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../elements/logo/Logo';
import footerOne from '../../data/footer/footerOne.json';
import ScrollTop from './ScrollTop';
const footerIntem = footerOne[0];
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];
const footerIntemFive = footerOne[5];
const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;
const indexThreeLink = footerIntemThree.quicklink;

const FooterOne = () => {
  return (
    <>
      <footer className='rn-footer footer-style-default'>
        <div className='footer-top'>
          <div className='container'>
            <div className='row'>
              {/* Start Single Widget  */}
              <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                <div className='rn-footer-widget'>
                  <div className='logo'>
                    <Logo image={`images/real/logo-h.png`} image2={`images/real/logo-h.png`} />
                  </div>
                  <h3 className='text-big' style={{ fontSize: 16 }}>
                    MORER-DX is committed to providing high-quality electronic components and equipment to our customers worldwide. Our team of
                    experts is dedicated to offering exceptional service and support to ensure your success. With competitive pricing, flexible
                    payment options, and fast delivery, we are your go-to supplier for all of your electronic needs. Thank you for choosing MORER-DX.
                  </h3>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div className='rn-footer-widget'>
                  <div className='widget-menu-top'>
                    <h4 className='title'>Quick Links</h4>
                    <div className='inner'>
                      <ul className='footer-link link-hover'>
                        <li>
                          <Link to='/'>Home</Link>
                        </li>
                        <li>
                          <Link to='/about'>About Us</Link>
                        </li>
                        <li>
                          <Link to='/services'>Services</Link>
                        </li>
                        <li>
                          <Link to='/contact'>Quote</Link>
                        </li>
                        <li>
                          <Link to='/terms'>Terms & Conditions</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className='col-lg-5 col-md-6 col-sm-6 col-12'>
                <div className='rn-footer-widget'>
                  <div className='widget-menu-top'>
                    <h4 className='title'>Contact Us</h4>
                    <div className='inner'>
                      <ul className='footer-link link-hover'>
                        <li>
                          <Link>Email: businessdevelopment@morerdx.com</Link>
                          <Link>Location: Av. de la Transición Española, 26, Edificio F, 28108 Alcobendas, Madrid, Spain</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterOne;
