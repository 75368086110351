import FooterOne from '../common/footer/FooterOne';
import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Copyright from '../common/footer/Copyright';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BlogClassicData from '../data/blog/BlogList.json';
import PortfolioOne from '../elements/portfolio/PortfolioOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import ServiceOne from '../elements/service/ServiceOne';

const Services = () => {
  return (
    <>
      <SEO title='Business Consulting' />
      <main className='page-wrapper'>
        <HeaderOne btnStyle='btn-small round btn-icon' HeaderSTyle='header-transparent' />

        {/* Start Service Area  */}
        <div className='rn-service-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Here is how we can help'
                  title='What are our services?'
                  description='As our team has a lot of experience in this field, we have learned a lot. Thus, we take pride in the services that we can provide.'
                />
              </div>
            </div>
            <ServiceOne serviceStyle='service__style--1 bg-color-blackest radius mt--25' textAlign='text-center' />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Portfolio Area  */}
        <div className='rwt-portfolio-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle=''
                  title='We provide solutions for'
                  description='Some of the biggest industries in the world'
                />
              </div>
            </div>
            <PortfolioOne Column='col-lg-4 col-md-6 col-12 mt--30 portfolio' />
          </div>
        </div>
        {/* End Portfolio Area  */}

        <FooterOne />
      </main>
    </>
  );
};
export default Services;
