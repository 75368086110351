import FooterOne from '../common/footer/FooterOne';
import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import ContactOne from '../elements/contact/ContactOne';
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';

const Terms = () => {
  return (
    <>
      <SEO title='Contact || Doob - React Business  Template' />
      <div className='main-content'>
        <HeaderOne btnStyle='btn-small round btn-icon' HeaderSTyle='header-transparent' />
        {/* Start Contact Area  */}
        {/* Start Slider Area  */}
        <div
          className='slider-area slider-style-1 height-850 bg_image'
          data-black-overlay='7'
          style={{ backgroundImage: `url(images/real/terms.jpg)` }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner pt--80 text-center'>
                  <h1 className='title display-one'>Terms & Conditions</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className='service-area rn-section-gapTop'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-10'>
                <h3 className='title'>Quotations</h3>
                <p className='mb--10'>
                  Quotations will be valid just in writing and for 4 days from the date of the quotation. All quotations by MORER-DX can change
                  without prior notice to buyer.
                </p>
                <br />
                <h3 className='title'>Price</h3>
                <p className='mb--10'>
                  Prices will be quoted in U.S. Dollars. Buyer agrees to pay transportation charges, including federal, state or local sales, use,
                  excise or similar taxes or assessments or import duties that are applicable to the sale, or transportation of the goods.
                </p>
                <br />
                <h3 className='title'>Shipping</h3>
                <p className='mb--10'>
                  MORER-DX will whip by the method deemed most suitable to each order. Any claims for products damaged or lost in transit will be made
                  by Buyer to the carrier, as our responsibility ends upon tender of goods to carrier.
                </p>
                <br />
                <h3 className='title'>Payments</h3>
                <p className='mb--10'>
                  Buyer is going to pay the goods in full, within the time frame and in the manner set in the contract. Payment needs to be done
                  within 30 days from the date of each invoice sent.{' '}
                </p>
                <br />
                <h3 className='title'>Warranty</h3>
                <p className='mb--10'>
                  MORER-DX warrants to the buyer that for a full year after the date on the receipt, all products on the invoice will be free from
                  defects and will function in conformance with the specifications provided by the manufacturer. MORER-DX is going to have the right
                  to replace or repair any defective products or to refund the purchase price.
                </p>
                <br />
                <h3 className='title'>Returns</h3>
                <p className='mb--10'>
                  Buyer can’t return any product without MORER-DX approval. All approved returns must be made in accordance with our return policies.
                  Products need to be in original packaging and be packaged in an appropriate manner to avoid any damage during shipping.
                </p>
              </div>
              <p className='mb--10'></p>
              <p className='mb--10'></p>
              <p className='mb--10'></p>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* End Contact Area  */}
        <FooterOne />
      </div>
    </>
  );
};
export default Terms;
