import React from 'react';
import { FiActivity, FiCast, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const ServiceList = [
  {
    icon: <FiActivity />,
    title: 'Testing',
    description:
      'We know how important functionality and quality is. Hence why we offer custom testing for any project that your company wants to implement. ',
  },
  {
    icon: <FiCast />,
    title: 'Support',
    description: 'When challenges appear regarding your order, our support team is here to help.',
  },
  {
    icon: <FiMap />,
    title: 'Fast Shipping',
    description: 'Time is important, especially when developing a project. All orders that contain components in stock are shipped within 24 hours.',
  },
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper'>
      {ServiceList.map((val, i) => (
        <div className='col-lg-4 col-md-6 col-sm-6 col-12' key={i}>
          <ScrollAnimation animateIn='fadeInUp' animateOut='fadeInOut' animateOnce={true}>
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className='icon'>{val.icon}</div>
              <div className='content'>
                <h4 className='title w-600'>
                  <Link to='#service' dangerouslySetInnerHTML={{ __html: val.title }}></Link>
                </h4>
                <p className='description b1 color-gray mb--0' dangerouslySetInnerHTML={{ __html: val.description }}></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
