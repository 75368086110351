import React from 'react';
import { Link } from 'react-router-dom';

const Copyright = () => {
  return (
    <div className='copyright-area copyright-style-one'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 col-md-8 col-sm-12 col-12'>
            <div className='copyright-left'>
              <ul className='ft-menu link-hover'>
                <li>
                  <a href='#'>
                    <Link to='/terms'>Privacy Policy</Link>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <Link to='/terms'>Terms And Condition</Link>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <Link to='/contact'>Contact Us</Link>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-6 col-md-4 col-sm-12 col-12'>
            <div className='copyright-right text-center text-md-end'>
              <p className='copyright-text'>© MORER-DX {new Date().getFullYear()}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Copyright;
