import FooterOne from '../common/footer/FooterOne';
import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import ContactOne from '../elements/contact/ContactOne';
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';

const Contact = () => {
  return (
    <>
      <SEO title='Contact || Doob - React Business  Template' />
      <div className='main-content'>
        <HeaderOne btnStyle='btn-small round btn-icon' HeaderSTyle='header-transparent' />
        {/* Start Contact Area  */}
        <div className='rwt-contact-area rn-section-gap'>
          <div className='container'>
            <ContactOne />
          </div>
        </div>
        {/* End Contact Area  */}
        <FooterOne />
      </div>
    </>
  );
};
export default Contact;
