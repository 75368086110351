import React, { useState, useEffect } from 'react';
import PortfolioData from '../../data/portfolio/PortfolioData.json';
import PortfolioItem from './PortfolioItem';
import { FaSpinner } from 'react-icons/fa';

const PortfolioOne = ({ Column }) => {
  return (
    <>
      <div className='row row--15'>
        {PortfolioData.map((item) => (
          <div key={item.id} className={Column}>
            <PortfolioItem portfolio={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default PortfolioOne;
