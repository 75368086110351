import React from 'react';
import ContactForm from './ContactForm';
import GoogleMapStyle from './GoogleMapStyle';
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';

const ContactOne = () => {
  return (
    <>
      <div className='row mt--40 row--15'>
        <div className='col-lg-7'>
          <ContactForm formStyle='contact-form-1' />
        </div>
        <div className='col-lg-5 mt_md--30 mt_sm--30'>
          <img src='images/real/contact-1.jpg' style={{ borderRadius: 8 }} />
        </div>
      </div>
    </>
  );
};
export default ContactOne;
