import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <ul className='mainmenu'>
      <li>
        <Link to='/'>Home</Link>
      </li>
      <li>
        <Link to='/about'>About Us</Link>
      </li>
      <li>
        <Link to='/services'>Services</Link>
      </li>
      <li>
        <Link to='/contact'>Quote</Link>
      </li>
      <li>
        <Link to='/terms'>Terms & Conditions</Link>
      </li>
    </ul>
  );
};
export default Nav;
