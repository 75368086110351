import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Copyright from '../common/footer/Copyright';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BlogClassicData from '../data/blog/BlogList.json';
import FooterOne from '../common/footer/FooterOne';
import FooterTwo from '../common/footer/FooterTwo';
import FooterThree from '../common/footer/FooterThree';
import FooterFour from '../common/footer/FooterFour';
var BlogListData = BlogClassicData.slice(0, 3);

const PopupData = [
  {
    id: '01',
    image: './images/bg/bg-image-4.jpg',
    popupLink: ['https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes'],
  },
];

const Home = () => {
  return (
    <>
      <SEO title='Business Consulting' />
      <main className='page-wrapper'>
        <HeaderOne btnStyle='btn-small round btn-icon' HeaderSTyle='header-transparent' />

        {/* Start Slider Area  */}
        <div
          className='slider-area slider-style-1 variation-default height-850 bg_image'
          data-black-overlay='7'
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/real/home.jpg)` }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner text-center'>
                  <span className='subtitle'>Electronic components distributor</span>
                  <h1 className='title display-one'>20 Years of Experience </h1>
                  <p className='description'>
                    MORER-DX is a leading distributor for electronic components around the globe. We provide reliable sourcing, pricing, and quality
                    for all of our services.
                  </p>
                  <div className='button-group'>
                    <Link className='btn-default btn-medium round btn-icon' to='/contact'>
                      Get Quote{' '}
                      <i className='icon'>
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <div className='service-area rn-section-gapTop'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>
                <p className='mb--10'>
                  Welcome to MORER-DX, your one-stop shop for electronic components and equipment. We provide a wide range of high-quality products
                  for all of your electronic needs. Our team is committed to providing exceptional service and support to our customers, no matter
                  what their needs may be.
                  <br />
                  <br />
                  At MORER-DX, we understand the importance of quality components for the success of your project. That's why we only offer the best
                  products from reliable and reputable manufacturers. We carry a wide range of components including semiconductors, capacitors,
                  resistors, inductors, and diodes, as well as various types of connectors and switches.
                  <br />
                  <br />
                  Our team of experts is always ready to help you find the right product for your specific application. We offer customized solutions
                  for your electronic requirements, including prototyping, testing, and design services. We also offer inventory management and
                  logistics support to ensure that your supply chain runs smoothly.
                  <br />
                  <br />
                  We are committed to providing competitive pricing and flexible payment options to fit your budget. Our active stock ensures fast
                  delivery and reduces lead times for your projects. With MORER-DX, you can trust that you are receiving the highest quality products
                  and services.
                  <br />
                  <br />
                  Thank you for choosing MORER-DX as your electronic component supplier. Browse our inventory or contact us today to learn more about
                  our products and services.
                </p>
              </div>
              <div className='col-md-6'>
                <div className='content' style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', height: '100%' }}>
                  <img src='images/real/home-1-1.png' />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <br />
            <br />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1 }}>
                <img src='images/real/home-1-2.png' />
              </div>

              <div style={{ flex: 0.1 }} />
              <div style={{ flex: 1 }}>
                <img src='images/real/home-1-3.png' />
              </div>
              <br />
              <br />
              <div style={{ flex: 0.1 }} />
              <div style={{ flex: 1 }}>
                <img src='images/real/home-1-4.png' />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />

        <FooterOne />
      </main>
    </>
  );
};
export default Home;
