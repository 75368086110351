import React from 'react';

const BrandList = [
  {
    image: 'https://monti-inc.com/wp-content/uploads/2022/11/monti.webp',
  },
  {
    image: 'https://iusawire.com/wp-content/uploads/2018/04/horizontal-logo.png',
  },
  {
    image: 'https://assets.alanwire.com/wp-content/uploads/2021/01/08150457/20_ALAN_02847_WhoWeAre-6MB_FNL_200.jpg',
  },
  {
    image: 'https://i.ibb.co/1XXGK22/DWC.png',
  },
];

const BrandThree = ({ brandStyle }) => {
  return (
    <ul className={`brand-list ${brandStyle}`}>
      {BrandList.map((data, index) => (
        <li key={index}>
          <a href='#'>
            <img src={`${data.image}`} alt='Brand Image' style={{ height: 30 }} />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default BrandThree;
