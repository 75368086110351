import FooterOne from '../common/footer/FooterOne';
import React from 'react';
import CountUp from 'react-countup';
import { FiArrowRight, FiCheck } from 'react-icons/fi';
import TrackVisibility from 'react-on-screen';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Copyright from '../common/footer/Copyright';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BlogClassicData from '../data/blog/BlogList.json';
import BrandThree from '../elements/brand/BrandThree';
import CounterUp from '../elements/counterup/CounterUp';
import CounterUpOne from '../elements/counterup/CounterUpOne';
import CounterUpTwo from '../elements/counterup/CounterUpTwo';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import SlipFour from '../elements/split/SlipFour';
import SlipThree from '../elements/split/SlipThree';
import SlipTwo from '../elements/split/SlipTwo';
import SlpitOne from '../elements/split/SlpitOne';
import Testimonial from '../elements/testimonial/Testimonial';
import TestimonialOne from '../elements/testimonial/TestimonialOne';
import TestimonialThree from '../elements/testimonial/TestimonialThree';
import TestimonialTwo from '../elements/testimonial/TestimonialTwo';
import VideoTwo from '../elements/video/VideoTwo';
import { slickDot } from '../utils/script';
var BlogListData = BlogClassicData.slice(0, 3);

const PopupData = [
  {
    id: '01',
    image: './images/bg/bg-image-4.jpg',
    popupLink: ['https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes'],
  },
];

const About = () => {
  return (
    <>
      <SEO title='Business Consulting' />
      <main className='page-wrapper'>
        <HeaderOne btnStyle='btn-small round btn-icon' HeaderSTyle='header-transparent' />

        {/* Start Service Area  */}
        <div className='service-area rn-section-gapTop'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='content'>
                  <h3 className='title'>MORER-DX specializes in sourcing hard to find components while quality is always maintained. </h3>
                </div>
              </div>
              <div className='col-lg-6'>
                <p className='mb--10'>
                  As we have rich history in this field, we can fully support the requirements that our customers have.
                  <br />
                  <br />
                  At our company, we have established strong partnerships with key industry players to support a wide range of industries. We take
                  pride in our commitment to delivering all orders in a timely and efficient manner.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Brand Area  */}
        <div className='rwt-brand-area pb--60 pt--30'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 mt--10'>
                <BrandThree brandStyle='brand-style-2' />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start --- Area  */}
        <div className='about-area about-style-4 rn-section-gap'>
          <div className='container'>
            <div className='row row--40 align-items-center'>
              <div className='col-lg-6'>
                {/* {[
                  {
                    id: '01',
                    image: './images/bg/bg-image-4.jpg',
                  },
                ].map((item) => (
                  <div className='video-btn' key={item.id}>
                    <VideoTwo imagename={`./images/bg/bg-image-4.jpg`} galleryItem={item} />
                  </div>
                ))} */}
                <div className='video-btn'>
                  <div className='video-popup icon-center'>
                    <div className='overlay-content'>
                      <div className='thumbnail'>
                        <img className='radius-small' src={`./images/real/about-1.jpg`} alt='Corporate Image' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 mt_md--40 mt_sm--40'>
                <div className='content'>
                  <div className='inner'>
                    <h3 className='title'>What makes MORER-DX different?</h3>
                    <ul className='feature-list'>
                      <li>
                        <div className='icon'>
                          <FiCheck />
                        </div>
                        <div className='title-wrapper'>
                          <h4 className='title'>High experienced team</h4>
                          <p className='text'>We take pride in having one of the best sales team available to assist you out.</p>
                        </div>
                      </li>
                      <li>
                        <div className='icon'>
                          <FiCheck />
                        </div>
                        <div className='title-wrapper'>
                          <h4 className='title'>Key Partners</h4>
                          <p className='text'>
                            Since we started our business, we developed many key partnerships with different suppliers all over the world. By doing so
                            we have access to more components for you.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              {[
                {
                  countNum: 20,
                  countTitle: 'Years of experience',
                },
                {
                  countNum: 20,
                  countSuffix: 'M',
                  countTitle: 'Tons of cargo shipped',
                },
                {
                  countNum: 100,
                  countTitle: 'Partners Worldwide',
                },
                {
                  countNum: 100,
                  countTitle: 'Partners Worldwide',
                },
              ].map((data, index) => (
                <div className='col-lg-3 col-md-3 col-sm-3 col-12' key={index}>
                  <div className='count-box counter-style-4 text-start'>
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && <div className='count-number'>{isVisible ? <CountUp end={data.countNum} suffix={data.countSuffix} /> : 0}</div>
                      }
                    </TrackVisibility>
                    <h5 className='counter-title'>{data.countTitle}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End --- Area  */}

        {/* Start --- Area  */}
        <div className='row'>
          <SectionTitle
            textAlign='text-center'
            radiusRounded=''
            // subtitle='What People think about us'
            title='What People think about us'
            description='We are grateful for all positive feedbacks we receive at MORER-DX.'
          />
          <div className='col-lg-12'>
            <Slider className='slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60' {...slickDot}>
              {[
                {
                  form: 'U.S.',
                  description: 'The team at MORER-DX really know what they are doing. Great support all the time for my orders!',
                  name: 'Emy Jurde',
                  subtitle: 'Buyer',
                  image: 'about-2',
                },
                {
                  form: 'U.S.',
                  description: 'Fast shipping and on time, couldn’t ask for more.',
                  name: 'John Due',
                  subtitle: 'Michael Don',
                  image: 'about-3',
                },
                {
                  form: 'U.S.',
                  description: 'They deliver what they promise. High quality components and professional support team. ',
                  name: 'Janen',
                  subtitle: 'Tomy Shawn',
                  image: 'about-4',
                },
              ].map((data, index) => (
                <div key={index} className={`testimonial-style-two`}>
                  <div className='row align-items-center row--20'>
                    <div className='order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1'>
                      <div className='content mt_sm--40'>
                        <span className='form'>{data.form}</span>
                        <p className='description'>{data.description}</p>
                        <div className='client-info'>
                          <h4 className='title'>{data.name}</h4>
                          <h6 className='subtitle'>{data.subtitle}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='order-1 order-md-2 col-lg-4 col-md-4'>
                      <div className='thumbnail'>
                        <img className='w-100' src={`./images/real/${data.image}.jpg`} alt='Corporate React Template' />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End --- Area  */}

        <FooterOne />
      </main>
    </>
  );
};
export default About;
