import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop';

// Pages import Here
import Business from './pages/Business';
import BusinessConsulting2 from './pages/BusinessConsulting2';
import Company from './pages/Company';
import Consulting from './pages/Consulting';
import Corporate from './pages/Corporate';
import CreativePortfolio from './pages/CreativePortfolio';
import DigitalAgency from './pages/DigitalAgency';
import EventConference from './pages/EventConference';
import Finance from './pages/Finance';
import Freelancer from './pages/Freelancer';
import HomeDefault from './pages/HomeDefault';
import InternationalConsulting from './pages/InternationalConsulting';
import Marketing from './pages/Marketing';
import PersonalPortfolio from './pages/PersonalPortfolio';
import SeoAgency from './pages/SeoAgency';
import Splash from './pages/Splash';
import Startup from './pages/Startup';
import TravelAgency from './pages/TravelAgency';
import WebAgency from './pages/WebAgency';

import AboutUs from './pages/AboutUs';
import Author from './pages/Author';
import CategoryList from './pages/CategoryList';
import TagList from './pages/TagList';

// Elements import Here

import Button from './elements/button/Button';
import CounterUp from './elements/counterup/CounterUp';
import Elements from './elements/elements/Elements';
import Progressbar from './elements/progressbar/Progressbar';
import Service from './elements/service/Service';

import RnAccordion from './elements/accordion/RnAccordion';
import AdvancePricing from './elements/advancePricing/AdvancePricing';
import AdvanceTab from './elements/advancetab/AdvanceTab';
import Brand from './elements/brand/Brand';
import CalltoAction from './elements/calltoaction/CalltoAction';
import Gallery from './elements/gallery/Gallery';
import Portfolio from './elements/portfolio/Portfolio';
import PortfolioBoxLayout from './elements/portfolio/PortfolioBoxLayout';
import PortfolioFullWidth from './elements/portfolio/PortfolioFullWidth';
import PortfolioGrid from './elements/portfolio/PortfolioGrid';
import portfolioThreeColumn from './elements/portfolio/PortfolioThreeColumn';
import Pricing from './elements/pricing/Pricing';
import Social from './elements/social/Social';
import Split from './elements/split/Split';
import Tab from './elements/tab/Tab';
import Team from './elements/team/Team';
import Testimonial from './elements/testimonial/Testimonial';
import Timeline from './elements/timeline/Timeline';
import Video from './elements/video/Video';
import PortfolioDetails from './pages/PortfolioDetails';
import Contact from './screens/Contact';

import BlogGridSidebar from './components/blog/BlogGridSidebar';
import BlogGridView from './components/blog/BlogGridView';
import BlogListSidebar from './components/blog/BlogListSidebar';
import BlogListView from './components/blog/BlogListView';
import BlogDetails from './pages/BlogDetails';
import Error from './pages/Error';

// Import Css Here
import './assets/scss/style.scss';
import About from './screens/About';
import Home from './screens/Home';
import Services from './screens/Services';
import Terms from './screens/Terms';

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route path={`${process.env.PUBLIC_URL + '/'}`} exact component={Home} />
          <Route path={`${process.env.PUBLIC_URL + '/index.html'}`} exact component={Home} />
          <Route path={`${process.env.PUBLIC_URL + '/about'}`} exact component={About} />
          <Route path={`${process.env.PUBLIC_URL + '/services'}`} exact component={Services} />
          <Route path={`${process.env.PUBLIC_URL + '/contact'}`} exact component={Contact} />
          <Route path={`${process.env.PUBLIC_URL + '/terms'}`} exact component={Terms} />
          <Route path={`${process.env.PUBLIC_URL + '/error'}`} exact component={Error} />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
